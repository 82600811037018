<template>
  <section class="box has-background-primary">
    <div class="columns is-vcentered">
      <div class="column">
        <span class="title is-6 has-text-white">{{ bannerText }}</span>
      </div>
      <div class="column is-narrow" v-if="cid">
        <button
          class="button is-info is-rounded is-size-6-touch"
          :class="{ 'is-loading': $wait.is('companion') }"
          :disabled="$wait.is('companion')"
          @click="goToCompanion"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import * as companion from 'modules/companion/services'
export default {
  name: 'AutoCheckCompanionUpsell',
  props: {
    vrm: { type: String, required: true },
    cid: { type: Number, required: false, default: () => null },
    date: { type: String, required: true },
    mileage: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser']),
    bannerText() {
      return this.cid
        ? 'Find out much more with Companion’s market view'
        : 'Unfortunately we could not value this vehicle. You will not be charged for this valuation'
    },
    buttonText() {
      return this.isCompanionUser ? 'Go to Companion' : 'Get started'
    }
  },
  methods: {
    async goToCompanion() {
      if (!this.isCompanionUser)
        return this.$modal.open('companion/UpsellCompanion')

      try {
        this.$wait.start('companion')
        const { vrm, mileage, date } = this
        await companion.value({ vrm, mileage, date })
      } finally {
        this.$wait.end('companion')
      }
    }
  }
}
</script>
